import React, {FC, useCallback} from 'react';
import {useTranslation} from "react-i18next";

import './assets/section.css';
import './assets/section-media.css';
import {ReactSVG} from "react-svg";
import arrow from '../../../assets/images/icons/sign_in.svg';
import arrow_next from '../../../assets/images/icons/arrow-watch.svg';
import image1 from './assets/image1.png';
import image2 from './assets/image2.jpg';
import image3 from './assets/image3.png';
import image4 from './assets/image4.jpg';
import image5 from './assets/image5.jpg';
import {useNavigate} from "react-router-dom";
import CircleOrion from "../../../components/CircleOrion";

interface Props {
}

const performers = [
  {name: 'FoolHardy', logo: image1, link: 'https://www.youtube.com/@FoolHardyTeam'},
  {name: 'Lex Salika', logo: image2, link: 'https://www.youtube.com/@LexSalika/featured'},
  {name: 'FoolHardy Reaction', logo: image3, link: 'https://www.youtube.com/@foolhardyreaction'},
  {name: 'STATION LIVE', logo: image4, link: 'https://www.youtube.com/@STATIONLIVETHEONE'},
  {name: 'Kpop Reactions', logo: image5, link: 'https://www.youtube.com/channel/UCEP59I52KQgKbuYGoAQKU4A'},
]

const Section_5: FC<Props> = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleRegister = useCallback(() => {
    navigate('/registration')
  }, [navigate]);

  return (
    <section className='section-5' id='section-5'>
      <div className='section5-content'>
        <div className='rotate-danger-text'>{t('MENU_4')}</div>
        <div className="section5-performers">
          <CircleOrion size={'lg'} bg={['yellow']}/>
          <CircleOrion size={'sm'} bg={['red']}/>

          {performers.map((performer, i) => (
            <div className='section5-performers-item' key={i}>
              <a target='_blank' href={performer.link} className="section5-performers-item-content">
                <div className='section5-performers-item-img'>
                  <img src={performer.logo} alt="logo"/>
                </div>
                <div className='text-medium flex-grow-1 text-center'>{performer.name}</div>
                <div className='d-flex align-items-center text-16'>
                  <span className='text-tint pe-2'>{t('WATCH')}</span>
                  <ReactSVG src={arrow_next} className='react-icon'/>
                </div>
              </a>
            </div>
          ))}
        </div>
        <div className='position-relative'>
          <h2 className='mb-4' style={{maxWidth: 453}} dangerouslySetInnerHTML={{__html: t('SECTION5_TITLE') || ''}}/>
          <p>{t('SECTION5_TEXT')}</p>
          <button type={'button'} className='btn btn-primary btn-lg justify-content-between' style={{minWidth: 200}}
                  onClick={handleRegister}>
            <span className='flex-grow-1 text-center'>{t('SIGN_UP')}</span>
            <ReactSVG src={arrow} className='react-icon btn-primary-icon ms-0'/>
          </button>
        </div>

      </div>
    </section>
  );
}

export default Section_5;
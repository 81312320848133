import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import img from './assets/img.png'

import './assets/section.css';
import './assets/section-media.css';
import CircleOrion from "../../../components/CircleOrion";

interface Props {
}

const Section_3: FC<Props> = () => {
  const {t} = useTranslation();

  return (
    <section className='section-3' id='section-3'>
        <h2 style={{maxWidth: 560}} dangerouslySetInnerHTML={{__html: t('SECTION3_TITLE') || ''}}/>
        {/*<div className='position-relative al'>*/}
        {/*  <CircleOrion size='xl' bg={['red']}/>*/}
        {/*  <CircleOrion size='sm'/>*/}
        {/*  <CircleOrion size='lg' bg={['yellow']}/>*/}
        {/*  <img src={img} alt="img"/>*/}
        {/*</div>*/}
        <div className='section3-texts'>
          <p dangerouslySetInnerHTML={{__html: t('SECTION3_TEXT_1') || ''}}/>
          <p dangerouslySetInnerHTML={{__html: t('SECTION3_TEXT_2') || ''}}/>
          <p dangerouslySetInnerHTML={{__html: t('SECTION3_TEXT_3') || ''}}/>
        </div>
    </section>
  );
}

export default Section_3;
import React, {FC, useCallback, useEffect, useLayoutEffect, useState} from 'react';
import Header from "../../components/Header";
import Section_1 from "./Section_1";
import Section_2 from "./Section_2";
import Section_3 from "./Section_3";
import Section_4 from "./Section_4";
import Section_5 from "./Section_5";
import Footer from "../../components/Footer";
import Sider from "./Sider";
import {API} from "../../utils/api";
import {EFieldGroup, IDoc} from "../../utils/rest";

export interface ITerms {terms:IDoc, privacy: IDoc}

interface Props {

}

const HomePage: FC<Props> = () => {
  const [siderDetailsVisible, setSiderDetailsVisible] = useState(false);
  const [terms, setTerms] = useState<ITerms>();

  useEffect(() => {
    fetchTerms()
  },[])

  const toggleSiderDetails = useCallback((visible: boolean) => {
    setSiderDetailsVisible(visible);
  }, []);

  const fetchTerms = useCallback(async() => {
    try {
      const [privacy, terms] = await Promise.all([
        API.Docs.getByTag('reactrino.privacy', [EFieldGroup.DocContent]),
        API.Docs.getByTag('reactrino.terms', [EFieldGroup.DocContent]),
      ]);
      setTerms({privacy, terms})
    } catch (e) {

    }
  }, []);

  return (
    <main>
      <Header  terms={terms}/>
      <Section_1/>
      <Sider siderDetailsVisible={siderDetailsVisible} toggleSiderDetails={toggleSiderDetails}/>
      <div className='overflow-hidden'>
        <div className="container">
          <div className="sections-content">
            <Section_2/>
            <Section_3/>
            <Section_4 onDetailsClick={() => setSiderDetailsVisible(true)}/>
            <Section_5/>
          </div>
        </div>
      </div>
      <Footer terms={terms}/>
    </main>
  );
}

export default HomePage;
import React, {FC, useCallback} from 'react';
import {useTranslation} from "react-i18next";
import bg from './assets/bg.png'

import './assets/section.css';
import './assets/section-media.css';
import CircleOrion from "../../../components/CircleOrion";
import {login_href} from "../../../data/constants";
import {useNavigate} from "react-router-dom";

interface Props {
}

const Section_1: FC<Props> = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleRegister = useCallback(() => {
    navigate('/registration')
  }, [navigate]);

  return (
    <section id='section-1'>
      <div className="container">
        <div className='section-1'>
          <CircleOrion size='sm' bg={['red']}/>
          <CircleOrion size='lg'/>
          <CircleOrion size='xl' bg={['red', 'yellow']}/>
          <div className='section1-text-wrap'>
            <h1 dangerouslySetInnerHTML={{__html: t('SECTION1_TITLE') || ''}}/>
            <button className='btn btn-primary' type='button' onClick={handleRegister}>
              {t('START_EARNING')}
            </button>
            {/*<a href={login_href} className='btn btn-primary'>{t('START_EARNING')}</a>*/}
          </div>
          <img src={bg} alt="bg" className='section1-bg'/>
        </div>
      </div>
    </section>
  );
}

export default Section_1;
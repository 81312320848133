import React, {FC, useCallback, useState} from 'react';
import {useTranslation} from "react-i18next";
import ReactSlider from 'react-slider'

import './assets/section.css';
import './assets/section-media.css';
import {Input} from "../../../components/FormControls";
import Select from "../../../components/FormControls/Select";

const coefficient: any = {
  us: {min: 4.8, max: 7.8, title: 'USA'},
  aus: {min: 7.1, max: 11.2, title: 'Australia'},
  de: {min: 2.8, max: 5.2, title: 'Germany'},
  ca: {min: 3.5, max: 5.1, title: 'Canada'},
  gb: {min: 2.7, max: 4.7, title: 'UK'},
  fr: {min: 2.5, max: 3.8, title: 'France'},
  es: {min: 2.2, max: 2.9, title: 'Spain'},
}

const calculate = (views: number, country: string, duration:string) => {
  const {min, max} = coefficient[country]
  let minProfit1000 = (min - (min * 0.5) - (min * 0.5 * 0.2));
  let maxProfit1000 = (max - (max * 0.5) - (max * 0.5 * 0.2));
  if (duration === '1') {
    minProfit1000 = minProfit1000 / 2
    maxProfit1000 = maxProfit1000 / 2
  }
  return {min:  minProfit1000 * views, max: maxProfit1000 * views}
}

interface Props {
}

const Section_2: FC<Props> = () => {
  const {t} = useTranslation();
  const [views, setViews] = useState(20);
  const [inputViews, setInputViews] = useState(20000);
  const [country, setCountry] = useState('us');
  const [durationMore, setDurationMore] = useState('0');

  const handleChange = useCallback((e: any) => {
    let value = e.target.value;
    if (value < 1000) value = 1000;
    else if (value > 500000) value = 500000;
    setInputViews(e.target.value);
    setViews(value / 1000);
  }, []);

  const {min, max} = calculate(views, country, durationMore);

  return (
    <section className='section-2' id='section-2'>
      <h2 dangerouslySetInnerHTML={{__html: t('SECTION2_TITLE') || ''}}/>
      <div className="section-2-content">
        <div className="section-2-content-form-wrap">
          <div className="section-2-content-form">
            <div className='text-bold' dangerouslySetInnerHTML={{__html: t('SECTION2_TITLE_1') || ''}}/>
            <div className='section-2-slider-wrap'>
              <div className='section-2-slider'>
                <ReactSlider
                  min={1}
                  max={500}
                  value={views}
                  onChange={(views) => {
                    setViews(views);
                    setInputViews(views * 1000)
                  }}
                  renderThumb={(props, state) => (
                    <div {...props} className='section-2-slider-track'>
                      <div className="section-2-slider-track-value">{state.value}.000</div>
                      <div className="section-2-slider-track-circle"/>
                    </div>
                  )}
                />
                <div className='section-2-slider-range text-muted-14'>
                  <div>1000</div>
                  <div>500.000</div>
                </div>
              </div>
              <div className='section-2-form-input-wrap'>
                <div className='text-muted-14 text-bold mb-1 ps-3'>{t('VIEWS')}</div>
                <Input
                  value={inputViews}
                  onChange={handleChange}
                  max={500}
                  min={1}
                  type='number'
                  className='section-2-form-input'
                />
              </div>
            </div>
            <div className='text-bold pt-0 pt-xl-3 mt-4 mt-xl-3' dangerouslySetInnerHTML={{__html: t('SECTION2_TITLE_2') || ''}}/>
            <Select className='my-3' value={country} onChange={(e) => setCountry(e.target.value)}>
              {Object.entries(coefficient).map(([ln, info]: any) => (
                <option value={ln}>{info.title}</option>
              ))}
            </Select>
            <div className='text-bold pt-2 pt-xl-3' dangerouslySetInnerHTML={{__html: t('SECTION2_TITLE_3') || ''}}/>
            <div className='mt-3'>
              <input
                type="radio"
                id="durationChoice1"
                name="video-duration"
                value={'0'}
                checked={durationMore === '0'}
                onChange={() => setDurationMore('0')}
              />
              <label htmlFor="durationChoice1">{t('SECTION2_RADIO_1')}</label>
            </div>
            <div className='mt-3'>
              <input
                type="radio"
                id="durationChoice2"
                name="video-duration"
                value="1"
                checked={durationMore === '1'}
                onChange={() => setDurationMore('1')}
              />
              <label htmlFor="durationChoice2">{t('SECTION2_RADIO_2')}</label>
            </div>
          </div>
        </div>
        <div className="section-2-content-result">
          <div className='position-relative'>
            <div className='text-bold section-2-content-result-title'>{t('SECTION2_TITLE_4')}</div>
            <div className="section-2-content-result-form">
              <div className='section-2-content-result-form-item'>
                <div className='text-14'>{t('PER_DAY')}</div>
                <div className='text-tint text-12'>≈ <span className='text-bold-16'>{min.toFixed(2)} - {max.toFixed(2)}</span> USD</div>
              </div>
              <div className='section-2-content-result-form-item'>
                <div className='text-14'>{t('PER_MONTH')}</div>
                <div className='text-tint text-12'>≈ <span className='text-bold-16'>{(min * 30).toFixed(0)} - {(max * 30).toFixed(0)}</span> USD</div>
              </div>
              <div className='section-2-content-result-form-item'>
                <div className='text-14'>{t('PER_YEAR')}</div>
                <div className='text-tint text-12'>≈ <span className='text-bold-16'>{(min * 30 * 12).toFixed(0)} - {(max * 30 * 12).toFixed(0)}</span> USD</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="d-flex flex-column align-items-center flex-xl-row w-100">*/}
      {/*  <div className="section2-text text-bold">*/}
      {/*    <p dangerouslySetInnerHTML={{__html: t('SECTION2_TEXT_1') || ''}} className='mb-3'/>*/}
      {/*    <p dangerouslySetInnerHTML={{__html: t('SECTION2_TEXT_2') || ''}} className='mb-3'/>*/}
      {/*    <p dangerouslySetInnerHTML={{__html: t('SECTION2_TEXT_3') || ''}} />*/}
      {/*  </div>*/}
      {/*  <div className="section2-graph">*/}
      {/*    {[50, 100, 200, 400, 800, 2000].map((item, i) => (*/}
      {/*      <div className='section2-graph-item' key={i}>*/}
      {/*        <div className={`text-14 pb-0 pb-md-2 text-nowrap${i === 5 ? ' text-bold text-tint' : ''}`}>$ {item}</div>*/}
      {/*        <div className='px-3   pt-3 mb-3 overflow-hidden w-100 position-relative' style={{height: (item * 100 / 2000 + 7) + '%'}}>*/}

      {/*          <div className="section2-graph-item-progress-top"/>*/}
      {/*          <div className="section2-graph-item-progress"/>*/}
      {/*        </div>*/}
      {/*        <div className="section2-graph-item-views">*/}
      {/*          <ReactSVG src={eye} className='react-icon' />*/}
      {/*          <span className='ms-0 ms-md-1'>{item * 2}k</span>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    ))}*/}
      {/*  </div>*/}
      {/*</div>*/}

    </section>
  );
}

export default Section_2;
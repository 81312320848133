import React, {FC, InputHTMLAttributes, useCallback} from 'react';

import {useTranslation} from 'react-i18next';
import {ReactSVG} from 'react-svg';

interface Props extends InputHTMLAttributes<any> {
  label?: string;
  className?: string;
  errorText?: string;
  error?: boolean;
  icon?: any;
}

const Input: FC<Props> = ({
                            label,
                            className,
                            icon,
                            error,
                            ...props
                          }) => {
  const {t} = useTranslation();

  return (
    <div className={`form-group${props.value ? ' filled' : ''}${error ? ' error' : ''} ${className || ''}`}>
      <input
        className={`form-control${icon ? ' form-control-icon' : ''}`}
        {...props}
        name={String(props?.value)}
        autoComplete='new-input'
      />
      {label ? <div className={`form-input-placeholder`}>{t(label)}</div> : null}
    </div>
  );
};

export default Input;
import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import arrow from "../../../assets/images/icons/arrow-right.svg";
import users from "../../../assets/images/icons/users.svg";
import close from "../../../assets/images/icons/close.svg";
import girl_1 from "../../../assets/images/sider_girl.png";
import girl_2 from "../../../assets/images/sider_girl_2.png";

import './sider.css';
import { school_href} from "../../../data/constants";
import Modal from "react-responsive-modal";

interface Props {
  siderDetailsVisible: boolean;
  toggleSiderDetails: (visible: boolean) => void;
}

const Sider: FC<Props> = ({toggleSiderDetails, siderDetailsVisible}) => {
  const {t} = useTranslation();

  const smallDevice = window.innerWidth < 1280;

  return (
    <>
      {/*<div className={`sider-wrap${siderDetailsVisible ? ' visible' : ''}`}>*/}
      <div className={`sider-wrap${siderDetailsVisible ? ' visible' : ''}`}>
        <div className={`sider`}>
          <div className="sider-ball"/>
          <div className="sider-small">
            <h3 className='text-center'>{t('SIDER_TITLE')}</h3>
            <div className='rotate-danger-text mb-3'>{t('NO_WORRIES')}</div>
            <p className='text-center'>{t('SIDER_TEXT_SMALL')}</p>
            <button
              type={'button'}
              onClick={() => toggleSiderDetails(true)}
              className={`btn btn-secondary w-100 mt-3`}
            >

              <span className='pe-3'>{t('SEE_DETAILS')}</span>
              <ReactSVG src={arrow} className='react-icon'/>
            </button>
            <img src={girl_1} alt="girl" className='sider-girl-small'/>
          </div>
        </div>
        {!smallDevice &&
        <div className="sider-big">
          <div className="sider-ball-big-wrap">
            <div className="sider-ball-big"/>
          </div>
          <div className='d-flex align-items-end position-relative'>
            <h3 className='mb-4 pb-2' style={{maxWidth: 480}}>{t('SIDER_TITLE')}</h3>
            <div className='rotate-danger-text mb-3'>{t('NO_WORRIES')}</div>
          </div>
          <div style={{maxWidth: 420}} className='position-relative'>
            <p className='mb-4'>{t('SIDER_TEXT')}</p>
            <div dangerouslySetInnerHTML={{__html: t('SIDER_TEXT_2') || ''}}/>
          </div>
          <div className="sider-close" onClick={() => toggleSiderDetails(false)}>
            <ReactSVG src={close} className='react-icon'/>
          </div>
          <img src={girl_2} alt="girl" className='sider-girl-big'/>
          <div className='sider-big-panel'>
            <div>
              <h4 className='mb-3'>{t('SIDER_TITLE_2')}</h4>
              <p>{t('SIDER_TEXT_3')}</p>
            </div>
            <a
              href={school_href}
              target='_blank'
              className='btn btn-primary btn-lg justify-content-between ms-4'
              style={{minWidth: 200}}
            >
              <span className='flex-grow-1 text-center'>{t('MENU_3')}</span>
              <ReactSVG src={users} className='react-icon btn-primary-icon ms-0'/>
            </a>
          </div>
        </div>
        }
      </div>
      {siderDetailsVisible && smallDevice
      ?
        <Modal
          open={siderDetailsVisible}
          onClose={() => toggleSiderDetails(false)}
          classNames={{modal: 'sider-modal', modalContainer: 'sider-modal-container'}}
          showCloseIcon={false}
        >
          <div className="sider-close" onClick={() => toggleSiderDetails(false)}>
            <ReactSVG src={close} className='react-icon'/>
          </div>
          <h3 className='text-center'>{t('SIDER_TITLE')}</h3>
          <div className='rotate-danger-text mb-3'>{t('NO_WORRIES')}</div>
          <p className='mb-4'>{t('SIDER_TEXT')}</p>
          <div dangerouslySetInnerHTML={{__html: t('SIDER_TEXT_2') || ''}}/>
          <div className='sider-big-panel mt-3'>
            <div>
              <h4 className='mb-3'>{t('SIDER_TITLE_2')}</h4>
              <p>{t('SIDER_TEXT_3')}</p>
            </div>
            <a
              href={school_href}
              target='_blank'
              className='btn btn-primary btn-lg justify-content-between w-100'
            >
              <span className='flex-grow-1 text-center'>{t('MENU_3')}</span>
              <ReactSVG src={users} className='react-icon btn-primary-icon ms-0'/>
            </a>
          </div>
        </Modal>
      :
        null
      }
    </>
  );
}

export default Sider;
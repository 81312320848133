import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";

import './assets/section.css';
import './assets/section-media.css';
import img from "./assets/img.png";
import {ReactSVG} from "react-svg";
import check from '../../../assets/images/icons/check.svg'
import close from '../../../assets/images/icons/close.svg'
import congr from '../../../assets/images/icons/congr.svg'
import hands from '../../../assets/images/icons/hands.svg'
import arrow from '../../../assets/images/icons/arrow-right.svg'

interface Props {
  onDetailsClick: () => void;
}

const Section_4: FC<Props> = ({onDetailsClick}) => {
  const {t} = useTranslation();
  const [status, setStatus] = useState('');

  return (
    <section className='section-4' id='section-4'>
      <div className='d-flex'>
      <h2 dangerouslySetInnerHTML={{__html: t('SECTION4_TITLE') || ''}}/>
        <div className='rotate-danger-text'>{t('IT_SIMPLE')}</div>
      </div>
      <div className='d-flex flex-column flex-xl-row'>
        <div>
          <img src={img} alt="img"/>
        </div>
        <div className='section4-texts'>
          <div className='text-bold mb-40'>
            <p dangerouslySetInnerHTML={{__html: t('SECTION4_TEXT_1') || ''}} className='mb-3 mb-xl-4'/>
            <p dangerouslySetInnerHTML={{__html: t('SECTION4_TEXT_2') || ''}}/>
          </div>
          <div className='d-flex'>
            <button
              style={{minWidth: 112}}
              type={'button'}
              onClick={() => setStatus('YES')}
              className={`btn ${status === 'YES' ? 'btn-primary' : 'btn-outline-primary'} me-3 ps-3`}
            >
              <ReactSVG src={check} className='react-icon'/>
              <span className='ps-2'>{t('YES')}</span>
            </button>
            <button
              style={{minWidth: 112}}
              type={'button'}
              onClick={() => setStatus('NO')}
              className={`btn ${status === 'NO' ? 'btn-primary' : 'btn-outline-primary'} ps-3`}
            >
              <ReactSVG src={close} className='react-icon'/>
              <span className='ps-2'>{t('NO')}</span>
            </button>
          </div>
          {status
            ?
            <div className='section4-status-wrap'>
              <ReactSVG src={status === 'YES' ? congr : hands} className='react-icon'/>
              <p dangerouslySetInnerHTML={{__html: t(`SECTION4_TEXT_${status}`) || ''}} className='mt-2 text-center'/>
              {status === 'NO'
                ?
                <button
                  type={'button'}
                  onClick={onDetailsClick}
                  className={`btn btn-secondary w-100 mt-3`}
                >

                  <span className='pe-3'>{t('SEE_DETAILS')}</span>
                  <ReactSVG src={arrow} className='react-icon'/>
                </button>
                :
                null}
            </div>
            :
            null
          }

        </div>
      </div>
    </section>
  );
}

export default Section_4;
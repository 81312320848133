import React, {FC, useCallback, useState} from 'react';
import {API} from '../../utils/api';
import {toast} from '../../utils/utils';
import {useTranslation} from "react-i18next";
import {Input, Textarea} from "../../components/FormControls";
import {EService, IYoutuberApplicationSubmitRequest} from "../../utils/rest";
import {ReactSVG} from "react-svg";
import arrow from "../../assets/images/icons/sign_in.svg";
import users_svg from './icons/users.svg';
import youtube_svg from './icons/youtube.svg';
import smile_svg from './icons/smile.svg';

interface Props {
  setSuccessForm: (status: boolean) => void;
}
interface IState extends Omit<Omit<IYoutuberApplicationSubmitRequest, 'links'>, 'contacts'> {
  links: string;
}

const RegistrationForm: FC<Props> = ({setSuccessForm}) => {
  const {t} = useTranslation();
  const [contacts, setContacts] = useState({whatsapp: '', tg: '', viber: ''});
  const [contactsError, setContactsError] = useState(false);
  const [form, setForm] = useState<IState>({email: '', links: '', views: ''});

  const [loading, setLoading] = useState(false);

  const handleChange = useCallback((key: string) => (e: any) => {
    setForm(prevState => {
      return {...prevState, [key]: e.target.value}
    })
  }, []);

  const handleChangeContacts = useCallback((contact: string) => (e: any) => {
    setContactsError(false);
    setContacts(prevState => {
      return {...prevState, [contact]: e.target.value}
    })
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (loading) return null;
    let resContacts = '';
    let contactError = true;
    Object.entries(contacts).forEach(([key, value], i) => {
      if (!value) return;
      contactError = false;
      resContacts += `${i ? ', ' : ''}${key}: ${value}`
    });
    if (contactError) return setContactsError(true);
    setLoading(true);
    try {
      await API.Applications.submitYoutuberApplication({
        ...form,
        contacts: resContacts,
        links: form.links?.split('\n'),
        service: EService.Reactrino,
        source: window.localStorage.getItem('source'),
        ref: window.localStorage.getItem('ref'),
      });
      // @ts-ignore
      // window.ym(91359429, 'reachGoal', 'reg');
      setSuccessForm(true);
    } catch (e: any) {
      toast(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="registration" onSubmit={handleSubmit}>
      <h2 className='mt-3 mb-0'>{t('REGISTRATION')}</h2>
      <div className="registration-separator"/>
      <div className='mb-4'>
      <div className='text-bold text-tint'>{t('FILL_REGISTRATION')}</div>
      {[users_svg, youtube_svg, smile_svg].map((item, i) => (
        <div className='mt-2 d-flex align-items-center'>
          <ReactSVG src={item} className='react-icon' style={{opacity:0.5}} />
          <div className={'ps-2'}>{t(`REGISTRATION_REQ_${ i + 1}`)}</div>
        </div>
      ))}
      </div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <Input
            required
            type='email'
            label={t('EMAIL') || ''}
            placeholder={t('EMAIL_PLACEHOLDER') || ''}
            value={form.email}
            onChange={handleChange('email')}
          />
        </div>
        <div className="col-12 col-lg-6">
          <Input
            required
            label={t('VIEWS_NUMBER') || ''}
            value={form.views}
            onChange={handleChange('views')}
          />
        </div>
      </div>
      <Textarea
        required
        label={'LINKS_PLACEHOLDER'}
        placeholder={'https://www.youtube.com/@...'}
        value={form.links}
        onChange={handleChange('links')}
      />
      <div className='bold mb-3 mb-lg-4'>{t('MESSENGER')}</div>
      <div className="row">
        <div className="col-12 col-lg-4">
          <Input
            error={contactsError}
            type='text'
            placeholder={t('WHATSAPP_PLACEHOLDER') || ''}
            label='WhatsApp'
            value={contacts.whatsapp}
            onChange={handleChangeContacts('whatsapp')}
          />
        </div>

        <div className="col-12 col-lg-4">
          <Input
            error={contactsError}
            type='text'
            placeholder={t('TG_PLACEHOLDER') || ''}
            label='Telegram'
            value={contacts.tg}
            onChange={handleChangeContacts('tg')}
          />
        </div>

        <div className="col-12 col-lg-4">
          <Input
            error={contactsError}
            type='text'
            placeholder={t('VIBER_PLACEHOLDER') || ''}
            label='Viber'
            value={contacts.viber}
            onChange={handleChangeContacts('viber')}
          />
        </div>
      </div>
      <button className='btn btn-primary btn-lg justify-content-between' style={{minWidth: 200}} type='submit'>
        <span className='flex-grow-1 text-center'>{t('SIGN_UP')}</span>
        <ReactSVG src={arrow} className='react-icon btn-primary-icon ms-0'/>
      </button>
    </form>
  );
};

export default RegistrationForm;
